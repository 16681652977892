/* eslint-disable @nx/enforce-module-boundaries */
import { PopoverTrigger } from "@chakra-ui/react"
import { GifIcon, GifIconV2 } from "@mybridge/icons"
import { IconButton } from "@mybridge/ui/icon-button"
import { useDisclosure } from "@mybridge/ui/hooks"
import { Popover, PopoverBody, PopoverContent } from "@mybridge/ui/popover"
import { useContext , useState } from "react"
import Giphy from "@mybridge/ui/giphy"
import { PostFormContext } from "../context"
import { Tooltip } from "@mybridge/ui/tooltip"
import { Box } from "@mybridge/ui/layout"

export const GifPicker = ({ hideOnSelect = true }) => {
    const { setGifs, images, videos, convertAndUploadThumbnail } = useContext(PostFormContext)
    const { isOpen, onClose, onToggle } = useDisclosure()
    const onSelect = (gif) => {
        setGifs?.([gif])
        convertAndUploadThumbnail(gif?.images?.original?.url)
        if (hideOnSelect) {
            onClose()
        }
    }
    const [iconColor, setIconColor] = useState('');

    const handleMouseEnter = () => {
        setIconColor('#ffffff');
    };
  
    const handleMouseLeave = () => {
      setIconColor('');
    };

    return (
        <>
            <Popover
                isOpen={isOpen}
                onClose={onClose}
            >
                <PopoverTrigger>
                    <Box>
                        <Tooltip variant="round" label="Add Gif">
                            <IconButton 
                                borderRadius="30px"
                                padding="6px"
                                _hover={{ background: '#3D5A80' }}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                isDisabled={images?.length || videos?.length} onClick={onToggle} icon={<GifIconV2 color={iconColor} />} />
                        </Tooltip>
                    </Box>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverBody>
                        {isOpen ? <Giphy onSelect={onSelect} /> : ""}
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    )
}